<template>
  <div class="all">
    <div class="isfix sctips"
         @click="tosc()"
         v-if="!this.$route.query.isshare">
      <!-- <img src="@/assets/xxb/shoucang.png"
           alt="">
      <span>{{this.wzdetail.iscollect == 'no' ? '收藏':'取消收藏'}}</span> -->
      <img src="@/assets/xxb/scq.png"
           alt=""
           v-if="this.wzdetail.iscollect == 'no'">
      <img src="@/assets/xxb/sch.png"
           alt=""
           v-if="this.wzdetail.iscollect == 'yes'">
      <span>{{this.wzdetail.iscollect == 'no' ? '收藏':'取消'}}</span>
    </div>
    <div>
      <div class="stitle">
        {{this.wzdetail.wztitle}}
      </div>
      <div id="wzdetails">

      </div>
    </div>
  </div>
</template>
<script>
import { getStorage } from '@/lib/util'
import { readWz, selectXbWz, addwzcollect, delwzcollect } from '@/api/xxb'
import { wechatshare } from '@/lib/wechat_share'
import config from '@/config'
export default {
  data () {
    return {
      type: '',
      wzdetail: {}
    };
  },
  created () {
    readWz({ wzcode: this.$route.query.wzcode }).then(res => { })
  },
  mounted () {
    selectXbWz({ wzinfo: this.$route.query.wzcode }).then(res => {
      this.wzdetail = res.data.data[0]
      $('#wzdetails').load(getStorage('ossurl', '') + this.wzdetail.wzinfo)
      this.$nextTick(() => {
        setTimeout(() => {
          document.title = this.wzdetail.wztitle ? this.wzdetail.wztitle : '学习'
        }, 500)
      })
      let shareurl = `${config.redirect_uri}/wzdetails?wzcode=${this.wzdetail.wzcode}&isshare=true`
      let path = window.location.href
      wechatshare(
        this.wzdetail.wztitle,
        this.wzdetail.wzdesc,
        getStorage('ossurl', '') + this.wzdetail.showhttp,
        shareurl,
        path,
        'no'
      )
    });
  },
  methods: {
    tosc () {
      if (this.wzdetail.iscollect == 'no') {
        addwzcollect({ empno: getStorage("u_s", {}).empno, wzcode: this.$route.query.wzcode }).then((res) => {
          this.wzdetail.iscollect = 'yes'
        })
      } else {
        delwzcollect({ wzcode: this.$route.query.wzcode }).then((res) => {
          this.wzdetail.iscollect = 'no'
        })
      }

    }
  },
};
</script>
<style scoped lang="stylus">
.stitle {
  font-weight: 400;
  line-height: 1rem;
  font-size: 0.5rem;
  margin: 0;
  background-color: #ffffff;
  padding: 0.5rem;
}

.all {
  height: 100%;
  overflow-y: auto;
}

.isfix {
  position: absolute;
  right: 4%;
  bottom: 22%;
  z-index: 99;
}

.sctips {
  position: fixed;
  right: 4%;
  bottom: 2rem;
  z-index: 99;
  background-color: rgba(255, 255, 255, 80%);
  // display: flex;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  // -webkit-box-pack: center;
  // -ms-flex-pack: center;
  // justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0rem 0rem 0.1rem 0.1rem #F1F1F1;

  img {
    width: 0.6rem;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 0.2rem;
  }

  span {
    color: #383838;
    font-weight: bold;
    font-size: 0.35rem;
  }
}
</style>